import React, { useCallback, useEffect, useState } from "react"
import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { Link } from "react-router-dom"
import {
  getStaffName,
  getUserName,
  locationIsGood,
} from "../../common/functions"
import {
  bookAppointments,
  fetchDoctors,
  fetchServices,
  sendOtpEmail,
  sendOtpSms,
  testToken,
  verifyOtp,
} from "../../helpers/api_requests"
import logoSm from "../../assets/images/logo-sm.png"
import Loader from "../../components/Common/Loader"
import { emailRegex, phoneRegex } from "../../constants/constants"
import CircularProgress from "../../components/Common/CircularProgress"
import countriesJson from "../../common/data/countries.json"

const initialData = {
  rawId: "",
  emrId: "",
  firstName: "",
  lastName: "",
  middleName: "",
  email: "",
  phoneNumber: "",
  gender: "",
  address: "",
  nationality: "nigeria",
  location: "",
  appointmentType: "",
  serviceId: "",
  serviceText: "",
  service: "",
  date: "",
  time: "",
  doctorId: "",
  doctor: "",
  tariff: "",
  description: "",
}

const initialMessage = {
  error: null,
  warning: null,
  success: null,
}

const servicesOptions = [
  "Consultation",
  "Routine Check-Up",
  "Deep Cleaning",
  "Tooth Pain",
  "Scaling and Polishing",
  "Teeth Whitening",
  "Veneers/Hollywood Smile",
  "Bleeding in the gum",
  "Braces or Invisalign",
  "Root Canal Therapy",
  "Dental Implant ",
  "Crowns or Dentures or Bridges",
  "Other",
]

const Appointments = () => {
  document.title = " Appointments | Zoom 36"
  const [activeTab, setactiveTab] = useState(1)
  const [filledTabs, setFilledTabs] = useState(1)
  const [haveId, setHaveId] = useState(false)
  const [data, setData] = useState(initialData)
  const [services, setServices] = useState([])
  const [doctors, setDoctors] = useState([])
  const [user, setUser] = useState(null)
  const [users, setUsers] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [username, setUsername] = useState("")
  const [selectedLocation, setSelectedLocation] = useState("")
  const [selectedType, setSelectedType] = useState("")
  const [message, setMessage] = useState(initialMessage)
  const [toasts, setToasts] = useState([])
  const [loading, setLoading] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [otp, setOtp] = useState("")
  const [timer, setTimer] = useState(0)
  const [identityConfirmed, setIdentityConfirmed] = useState(false)
  const [showLocationOptions, setShowLocationOptions] = useState(false)
  const [showServiceTextField, setShowServiceTextField] = useState(false)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
      }
    }
    if (tab > filledTabs) {
      setFilledTabs(tab)
    }
  }

  const addToast = newMessage => {
    const newToast = { id: toasts.length, ...newMessage }
    setToasts([...toasts, newToast])

    setTimeout(() => {
      setToasts(prevToasts =>
        prevToasts.filter(toast => toast.id !== newToast.id)
      )
    }, 3000)
  }

  const changeData = prop => {
    setData({ ...data, ...prop })
  }

  const setDisplayName = useCallback(() => {
    const currentUsername = `${data?.firstName} ${data.lastName}`
    if (window && currentUsername !== username) {
      const existingAuthUser = JSON.parse(localStorage.getItem("authUser"))
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          ...existingAuthUser,
          displayName: currentUsername,
        })
      )
      setUsername(currentUsername)
    }
  }, [data, username])

  useEffect(() => {
    setDisplayName()
  }, [setDisplayName])

  const sendOtp = async type => {
    try {
      resetMessage()
      setLoading(true)

      if (!data.email && !data.emrId && !data.phoneNumber) {
        setMessage({ warning: "Please enter email or clinic ID!" })
        return
      }
      if (!data.location || !locationIsGood(data.location)) {
        setMessage({
          warning: "Selected location not valid, please refresh and try again!",
        })
        return
      }
      switch (type) {
        case "email":
          console.log({ destination: data.email, location: data.location })
          const eResp = await sendOtpEmail({
            emrId: user?.rawId ? user.rawId : data.emrId,
            destination: user?.email ? user.email : data.email,
            location: data.location,
          })

          if (eResp.success) {
            if (eResp.data?.length > 1) {
              setMessage({ success: "Please select your name!" })
              setUsers(eResp.data)
            } else {
              setUser(eResp.data[0])
              setMessage({ success: "Please enter code sent to your email!" })
              setOtpSent(true)
              setData({ ...data, ...eResp.data[0] })
            }
          } else {
            setMessage({ error: eResp?.message || "Error making request!" })
          }
          break

        case "sms":
          const sResp = await sendOtpSms({
            emrId: user?.rawId ? user.rawId : data.emrId,
            destination: user?.phoneNumber
              ? user?.phoneNumber
              : data.phoneNumber,
            location: data.location,
          })

          if (sResp.success) {
            if (eResp.data?.length > 1) {
              setMessage({ success: "Please select your name!" })
              setUsers(eResp.data)
            } else {
              setUser(eResp.data[0])
              setMessage({ success: "Please enter code sent to your phone!" })
              setOtpSent(true)
              setData({ ...data, ...eResp.data[0] })
            }
          } else {
            setMessage({ error: sResp?.message || "Error making request!" })
          }
          break

        default:
          const iResp = await sendOtpEmail({
            emrId: user?.rawId ? user.rawId : data.emrId,
            destination: user?.email ? user.email : data.email,
            location: data.location,
          })

          if (iResp.success) {
            if (iResp.data?.length > 1) {
              setMessage({ success: "Please select your name!" })
              setUsers(iResp.data)
            } else {
              setUser(iResp.data[0])
              setMessage({ success: "Please enter code sent to your phone!" })
              setOtpSent(true)
              setData({ ...data, ...iResp.data[0] })
            }
          } else {
            setMessage({ error: sResp?.message || "Error making request!" })
          }
          break
      }
    } catch (error) {
      setMessage({
        error: error?.response?.data?.message || "Error making request!",
      })
    }
  }

  const doVerifyOtp = async () => {
    try {
      resetMessage()
      setLoading(true)

      if (isNaN(otp)) {
        setMessage({ error: "OTP must contain integers only!" })
        return
      }

      const resp = await verifyOtp({
        code: Number(otp),
        email: data.email,
        phoneNumber: data.phoneNumber,
      })
      if (resp.success) {
        setTimer(180)
        setMessage({ success: "success!" })
        setIdentityConfirmed(true)
        setactiveTab(2)
        const { token: newToken, ...restData } = resp.data
        setTokenToStorage(newToken)
        setData({ ...data, ...restData })
        setTimeout(() => {
          setTimer(0)
        }, 180)
      } else {
        setMessage({ error: resp?.message || "Error making request!" })
      }
    } catch (error) {
      setMessage({
        error: error?.response?.data?.message || "Error making request!",
      })
    }
  }

  const getSimplifiedAddress = loc => {
    switch (loc?.toLowerCase()) {
      case "lag":
        return "... Lagos"
      case "fct":
        return "... Abuja"
      case "phc":
        return "... Port Harcourt"
      default:
        return ""
    }
  }

  const doSubmit = async () => {
    try {
      resetMessage()
      setLoading(true)

      const resp = await bookAppointments({
        ...data,
        address: data.address
          ? data.address
          : getSimplifiedAddress(data.location),
        patient_id: user?.emrId ? user.emrId : data.emrId,
        surname: data.firstName,
        other_names: `${data.lastName} ${data.middleName}`,
        date_of_birth: data.dateOfBirth,
        phone_number: data.phoneNumber,
        appointment_date: data.date,
        consultation_id: "initial",
        department_id:
          data.doctor?.department_id || data.doctor?.department?.id,
        start_time: data.time,
        duration: 30,
        duration_type: "m",
        consulting_room_id: null,
        doctor_id: data.doctorId,
        service_id: data.serviceId,
        bill: false,
        token: getTokenFromStorage(),
      })
      if (resp.success) {
        setSubmitted(true)
        setMessage({ success: resp?.message || "Error making request!" })
      } else {
        setMessage({ error: resp?.message || "Error making request!" })
      }
    } catch (error) {
      setMessage({
        error: error?.response?.data?.message || "Error making request!",
      })
    }
  }

  const setTokenToStorage = payload => {
    try {
      if (window) {
        return sessionStorage.setItem("token", JSON.stringify(payload))
      }
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getTokenFromStorage = () => {
    try {
      if (window) {
        console.log(JSON.parse(sessionStorage.getItem("token") || "{}"))
        return JSON.parse(sessionStorage.getItem("token") || "{}")
      }
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const getMessageText = () => {
    if (message.error) {
      return { text: message.error, color: "danger" }
    } else if (message.warning) {
      return { text: message.warning, color: "warning" }
    } else if (message.success) {
      return { text: message.success, color: "success" }
    } else {
      return { text: "", color: "" }
    }
  }

  const resetMessage = () => {
    setMessage(initialMessage)
  }

  const fetchServicesData = async () => {
    try {
      setLoading(true)
      const resp = await fetchServices({
        location: data.location,
        type: data.appointmentType,
      })
      console.log("RESP SERVICE ", resp)
      if (resp.success) {
        setServices(resp.data)
        setLoading(false)
        setShowServiceTextField(false)
        if (!doctors?.length) {
          fetchDoctors()
        }
      } else {
        // setMessage({ warning: resp.message || "Failed to fetch services" })
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setShowServiceTextField(true)
      setLoading(false)
      // setMessage({
      //   warning:
      //     "Error fetching services " +
      //     data.location?.toUpperCase() +
      //     " server can't be reached!",
      // })
    }
  }

  const fetchDoctorsData = async () => {
    try {
      setLoading(true)
      const resp = await fetchDoctors({ location: data.location })
      // console.log("RESP DOCTORS ", resp)
      if (resp.success) {
        setDoctors(resp.data)
        setLoading(false)
        setHaveId(true)
        setShowLocationOptions(false)
        setShowServiceTextField(false)
      } else {
        setLoading(false)
        // setMessage({
        //   warning: resp.message || "Failed to fetch doctors/ dental therapists",
        // })
      }
    } catch (error) {
      // console.log(error)
      setShowLocationOptions(false)
      setactiveTab(2)
      setLoading(false)
      // setMessage({
      //   warning:
      //     "Error fetching doctors/ dental therapists, " +
      //     data.location?.toUpperCase() +
      //     " server can't be reached! \nYou can still book appointment",
      // })
    }
  }

  const doTestToken = async () => {
    try {
      const resp = await testToken({ token: getTokenFromStorage() })
      if (!resp?.success) {
        localStorage.setItem("savedData", "")
        sessionStorage.setItem("token", "")
        setIdentityConfirmed(false)
      } else if (window) {
        const savedData = JSON.parse(localStorage.getItem("savedData") || "{}")
        if (savedData) {
          setData(savedData)
          setIdentityConfirmed(true)
        }
      }
    } catch (error) {
      setactiveTab(1)
      localStorage.setItem("savedData", "")
      sessionStorage.setItem("token", "")
      setIdentityConfirmed(false)
      console.log(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    if (getMessageText().text) {
      setLoading(false)
      addToast({ content: getMessageText().text, type: getMessageText().type })
      setTimeout(() => {
        setMessage(initialMessage)
      }, 10000)
    }
    // eslint-disable-next-line
  }, [message])

  useEffect(() => {
    if (window) {
      // localStorage.setItem("savedData", JSON.stringify(data))
    }
    if (
      data.appointmentType !== selectedType &&
      data.location &&
      data.appointmentType
    ) {
      fetchServicesData()
      setSelectedType(data.appointmentType)
    }
    if (data.location !== selectedLocation && data.location) {
      fetchDoctorsData()
      setSelectedLocation(data.location)
    }
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    doTestToken()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setactiveTab(2)
    // eslint-disable-next-line
  }, [identityConfirmed])

  const levelVerified = () => {
    const {
      emrId,
      firstName,
      lastName,
      email,
      phoneNumber,
      appointmentType,
      date,
      time,
      location,
    } = data
    if (activeTab === 2) {
      if (
        !emrId &&
        (!firstName || !lastName || !phoneNumber || !email || !location)
      ) {
        setMessage({ warning: "Please fill all required fields!" })
        setLoading(false)
        return false
      }
    }
    if (activeTab === 3) {
      if (!appointmentType || !date || !time) {
        setMessage({ warning: "Please fill all required fields!" })
        setLoading(false)
        return false
      }
    }
    resetMessage()
    return true
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Zoom 36" breadcrumbItem="Appointments" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 text-center">
                      {message?.error ||
                      message?.success ||
                      message?.warning ? (
                        <Alert color={getMessageText().color}>
                          {getMessageText().text}
                        </Alert>
                      ) : submitted ? (
                        ""
                      ) : (
                        "Schedule an appointment"
                      )}
                    </CardTitle>
                    <div className="wizard clearfix">
                      {!submitted && (
                        <div className="steps clearfix cursor-pointer d-none d-sm-block">
                          <ul>
                            <NavItem
                              className={classnames({
                                current: activeTab === 1,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                                onClick={() => {
                                  if (filledTabs >= 1 && !submitted) {
                                    setactiveTab(1)
                                  }
                                }}
                              >
                                <span className="number">1.</span>
                                Validation
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 2,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 2,
                                })}
                                onClick={() => {
                                  if (filledTabs >= 2 && !submitted) {
                                    setactiveTab(2)
                                  }
                                }}
                              >
                                <span className="number me-2">2.</span>{" "}
                                {haveId
                                  ? "Confirm Details"
                                  : "Personal Information"}
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 3,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 3,
                                })}
                                onClick={() => {
                                  if (filledTabs >= 3 && !submitted) {
                                    if (levelVerified()) {
                                      setactiveTab(3)
                                    }
                                  }
                                }}
                              >
                                <span className="number">3.</span>
                                Booking Details
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 4,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 4,
                                })}
                                onClick={() => {
                                  if (filledTabs >= 4 && !submitted) {
                                    if (levelVerified()) {
                                      setactiveTab(4)
                                    }
                                  }
                                }}
                              >
                                <span className="number">4.</span>
                                Confirmation
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                      )}
                      <div className="content clearfix">
                        <div className="body">
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                              {haveId !== true ? (
                                showLocationOptions ? (
                                  <Form style={{ minHeight: "200px" }}>
                                    <Row>
                                      <Col md={12}>
                                        <div className="mb-3 text-center">
                                          <Label
                                            htmlFor="formrow-firstname-Input"
                                            className="col-md-6"
                                          >
                                            Where did you register?
                                          </Label>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "15px",
                                          display: "inline",
                                          marginRight: "10px",
                                          cursor: "pointer",
                                        }}
                                        className="bg-success rounded text-white p-1"
                                        onClick={() => {
                                          setData({ ...data, location: "fct" })
                                        }}
                                      >
                                        Abuja
                                      </div>
                                      <div
                                        style={{
                                          fontSize: "15px",
                                          display: "inline",
                                          marginRight: "10px",
                                          cursor: "pointer",
                                        }}
                                        className="bg-primary rounded text-white p-1"
                                        onClick={() => {
                                          setData({ ...data, location: "phc" })
                                        }}
                                      >
                                        Port Harcourt
                                      </div>
                                      <div
                                        style={{
                                          fontSize: "15px",
                                          display: "inline",
                                          marginRight: "10px",
                                          cursor: "pointer",
                                        }}
                                        className="bg-warning rounded text-secondary p-1"
                                        onClick={() => {
                                          setData({
                                            ...data,
                                            location: "lag",
                                          })
                                        }}
                                      >
                                        Lagos
                                      </div>
                                    </div>
                                    <Row>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontSize: "40px",
                                            display: "inline",
                                            marginTop: "20px",
                                          }}
                                          onClick={() => {
                                            setShowLocationOptions(false)
                                          }}
                                        >
                                          <i className="fa fa-arrow-circle-left text-secondary" />
                                        </div>
                                      </div>
                                    </Row>
                                  </Form>
                                ) : (
                                  <Form style={{ minHeight: "200px" }}>
                                    <Row>
                                      <Col md={12}>
                                        <div className="mb-3 text-center">
                                          <Label
                                            htmlFor="formrow-firstname-Input"
                                            className="col-md-6"
                                          >
                                            Have you registered with the clinic?
                                          </Label>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "40px",
                                          display: "inline",
                                          marginRight: "10px",
                                        }}
                                        onClick={() => {
                                          setShowLocationOptions(true)
                                          setSelectedLocation("")
                                        }}
                                      >
                                        <i className="fa fa-check-circle text-success" />
                                      </div>

                                      <div
                                        style={{
                                          fontSize: "40px",
                                          display: "inline",
                                          marginLeft: "10px",
                                        }}
                                        onClick={() => {
                                          setHaveId(false)
                                          setactiveTab(2)
                                        }}
                                      >
                                        <i className="fa fa-times-circle text-secondary" />
                                      </div>
                                    </div>
                                  </Form>
                                )
                              ) : otpSent ? (
                                <Form style={{ minHeight: "200px" }}>
                                  <Row>
                                    <div
                                      style={{
                                        display: "none",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <CircularProgress
                                        size={50}
                                        strokeWidth={5}
                                        duration={180}
                                      />
                                    </div>
                                  </Row>
                                  <Row>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="my-3 text-center">
                                        <Label htmlFor="formrow-otp-Input">
                                          Enter OTP
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-otp-Input"
                                          value={otp}
                                          onChange={e => {
                                            setOtp(e.target.value)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Row>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "40px",
                                        display: "inline",
                                        marginRight: "10px",
                                      }}
                                      disabled={timer < 0}
                                      onClick={() => {
                                        setOtpSent(false)
                                        setHaveId(false)
                                        setData({
                                          ...data,
                                          email: "",
                                          phoneNumber: "",
                                          emrId: "",
                                        })
                                      }}
                                    >
                                      <i className="fa fa-arrow-circle-left text-secondary" />
                                    </div>
                                    <div
                                      className="btn btn-primary"
                                      style={{
                                        display: "inline",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() => {
                                        doVerifyOtp()
                                      }}
                                    >
                                      Verify
                                    </div>
                                  </div>
                                </Form>
                              ) : users.length > 1 && !user ? (
                                <Form style={{ minHeight: "200px" }}>
                                  <Row>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="my-3 text-center">
                                        <Label htmlFor="formrow-InputUser">
                                          Please select your name
                                        </Label>
                                        <select
                                          id="formrow-InputUser"
                                          className="form-control"
                                          value={data.user?.rawId}
                                          onChange={e => {
                                            const selectedUser = users.find(
                                              // eslint-disable-next-line
                                              u => u.rawId == e.target.value
                                            )
                                            console.log({ selectedUser })
                                            setUser(selectedUser)
                                          }}
                                        >
                                          <option value={""}>Choose...</option>
                                          {users?.map((u, i) => (
                                            <option key={i} value={u.rawId}>
                                              {getUserName(u)}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </Row>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "40px",
                                        display: "inline",
                                        marginRight: "10px",
                                      }}
                                      onClick={() => {
                                        setHaveId(false)
                                        setData({
                                          ...data,
                                          email: "",
                                          phoneNumber: "",
                                          emrId: "",
                                        })
                                        setUser(null)
                                        setUsers([])
                                      }}
                                    >
                                      <i className="fa fa-arrow-circle-left text-secondary" />
                                    </div>
                                    <div
                                      className="btn btn-primary"
                                      style={{
                                        display: "inline",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() => {
                                        sendOtp("email")
                                      }}
                                    >
                                      Confirm
                                    </div>
                                  </div>
                                </Form>
                              ) : (
                                <Form style={{ minHeight: "200px" }}>
                                  <Row>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="my-3 text-center">
                                        <Label htmlFor="formrow-id-Input">
                                          Please enter your "Clinic ID" or
                                          registered email address
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-id-Input"
                                          value={
                                            data.email ||
                                            data.phoneNumber ||
                                            data.emrId
                                          }
                                          onChange={e => {
                                            if (
                                              emailRegex.test(e.target?.value)
                                            ) {
                                              changeData({
                                                email: e.target.value,
                                                phoneNumber: "",
                                                emrId: "",
                                              })
                                            } else if (
                                              phoneRegex.test(
                                                e.target?.value
                                              ) &&
                                              e.target.value?.length >= 8
                                            ) {
                                              changeData({
                                                email: "",
                                                emrId: "",
                                                phoneNumber: e?.target?.value,
                                              })
                                            } else {
                                              changeData({
                                                email: "",
                                                phoneNumber: "",
                                                emrId: e?.target?.value,
                                              })
                                            }
                                            console.log({
                                              email: data.email,
                                              phoneNumber: data.phoneNumber,
                                              emrId: data.emrId,
                                            })
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Row>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "40px",
                                        display: "inline",
                                        marginRight: "10px",
                                      }}
                                      onClick={() => {
                                        setHaveId(false)
                                        setData({
                                          ...data,
                                          email: "",
                                          phoneNumber: "",
                                          emrId: "",
                                        })
                                      }}
                                    >
                                      <i className="fa fa-arrow-circle-left text-secondary" />
                                    </div>
                                    <div
                                      className="btn btn-primary"
                                      style={{
                                        display: "inline",
                                        marginLeft: "10px",
                                      }}
                                      onClick={() => {
                                        if (data.phoneNumber) {
                                          sendOtp("sms")
                                        } else if (data.email) {
                                          sendOtp("email")
                                        } else {
                                          sendOtp("id")
                                        }
                                      }}
                                    >
                                      Confirm
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </TabPane>
                            <TabPane tabId={2}>
                              <Form style={{ minHeight: "200px" }}>
                                <Row>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        First Name*
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        value={data.firstName}
                                        disabled={haveId}
                                        onChange={e => {
                                          changeData({
                                            firstName: e.target?.value,
                                          })
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-lastname-Input">
                                        Last Name*
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-lastname-Input"
                                        value={data.lastName}
                                        disabled={haveId}
                                        onChange={e => {
                                          changeData({
                                            lastName: e.target?.value,
                                          })
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-dateOfBirth-Input">
                                        Age
                                      </Label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        value={data.age}
                                        min={10}
                                        disabled={haveId}
                                        id="formrow-age-Input"
                                        onChange={e => {
                                          changeData({
                                            age: e.target?.value,
                                          })
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={8}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-email-Input">
                                        Email*
                                      </Label>
                                      <Input
                                        type="email"
                                        className="form-control"
                                        id="formrow-email-Input"
                                        value={data.email}
                                        disabled={haveId}
                                        onChange={e => {
                                          changeData({
                                            email: e.target?.value,
                                          })
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-phone-Input">
                                        Phone Number*
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-phone-Input"
                                        value={data.phoneNumber}
                                        disabled={haveId}
                                        onChange={e => {
                                          changeData({
                                            phoneNumber: e.target?.value,
                                          })
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputGender">
                                        Gender
                                      </Label>
                                      <select
                                        id="formrow-InputGender"
                                        className="form-control"
                                        value={data.gender}
                                        disabled={haveId}
                                        onChange={e => {
                                          changeData({
                                            gender: e.target?.value,
                                          })
                                        }}
                                      >
                                        <option value={""}>Choose...</option>
                                        <option value={"female"}>Female</option>
                                        <option value={"male"}>Male</option>
                                        <option value={"none"}>
                                          I prefer not to say.
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputNationality">
                                        Nationality
                                      </Label>
                                      <select
                                        id="formrow-InputNationality"
                                        className="form-control"
                                        value={data.nationality}
                                        disabled={haveId}
                                        onChange={e => {
                                          changeData({
                                            nationality: e.target?.value,
                                          })
                                        }}
                                      >
                                        <option value={"nigeria"}>
                                          Nigeria
                                        </option>
                                        {countriesJson
                                          ?.filter(c => c.value !== "nigeria")
                                          ?.map((c, i) => (
                                            <option key={i} value={c.value}>
                                              {c.label}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-location">
                                        Prefered Location*
                                      </Label>
                                      <select
                                        id="formrow-location"
                                        className="form-control"
                                        value={data.location}
                                        disabled={haveId}
                                        onChange={e => {
                                          changeData({
                                            location: e.target?.value,
                                          })
                                        }}
                                      >
                                        <option value={""}>Choose...</option>
                                        <option value={"fct"}>Abuja</option>
                                        <option value={"lag"}>Lagos</option>
                                        <option value={"phc"}>
                                          Port Harcourt
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                                {/* <Row>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-address-Input">
                                      Address
                                    </Label>
                                    <Input
                                      type="address"
                                      className="form-control"
                                      id="formrow-address-Input"
                                      value={data.address}
                                      disabled={haveId}
                                      onChange={e => {
                                        changeData({
                                          address: e.target?.value,
                                        })
                                      }}
                                    />
                                  </div>
                                </Row> */}
                              </Form>
                            </TabPane>
                            <TabPane tabId={3}>
                              <Form style={{ minHeight: "200px" }}>
                                <Row>
                                  <Col lg={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-location">
                                        Appointment Type*
                                      </Label>
                                      <select
                                        id="formrow-location"
                                        className="form-control"
                                        value={data.appointmentType}
                                        onChange={e => {
                                          changeData({
                                            appointmentType: e.target?.value,
                                          })
                                          setServices([])
                                        }}
                                      >
                                        <option value={""}>Choose...</option>
                                        <option value={"consultation"}>
                                          Consultation
                                        </option>
                                        <option value={"procedure"}>
                                          Procedure
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col md={8}>
                                    <div className="mb-3">
                                      {!showServiceTextField ? (
                                        <Label htmlFor="formrow-lastname-Input">
                                          {data.appointmentType === "procedure"
                                            ? "Procedure"
                                            : data.appointmentType ===
                                              "consultation"
                                            ? "Consultation Category"
                                            : "Service"}
                                          *
                                        </Label>
                                      ) : (
                                        <Label>
                                          Why do you want to see a Doctor?
                                        </Label>
                                      )}
                                      {showServiceTextField ? (
                                        <select
                                          id="formrow-services"
                                          className="form-control"
                                          value={data.serviceText}
                                          onChange={e => {
                                            changeData({
                                              serviceText: e.target?.value,
                                            })
                                          }}
                                        >
                                          <option value={""}>Choose...</option>
                                          {servicesOptions?.map((s, i) => {
                                            return (
                                              <option key={i} value={s}>
                                                {s}
                                              </option>
                                            )
                                          })}
                                        </select>
                                      ) : (
                                        <select
                                          id="formrow-services"
                                          className="form-control"
                                          value={data.serviceId}
                                          onChange={e => {
                                            if (
                                              [
                                                "procedure",
                                                "consultation",
                                              ].includes(data.appointmentType)
                                            ) {
                                              changeData({
                                                serviceId: e.target?.value,
                                                service: services?.find(
                                                  s => s.id === e.target?.value
                                                ),
                                              })
                                              changeData({ serviceText: "" })
                                            }
                                          }}
                                        >
                                          <option value={""}>Choose...</option>
                                          {services?.map((s, i) => {
                                            return (
                                              <option key={i} value={s?.id}>
                                                {s?.name}
                                              </option>
                                            )
                                          })}
                                        </select>
                                      )}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-date-Input">
                                        Date*
                                      </Label>
                                      <input
                                        className="form-control"
                                        type="date"
                                        value={data.date}
                                        min={
                                          new Date().toISOString().split("T")[0]
                                        }
                                        id="formrow-date-Input"
                                        onChange={e => {
                                          changeData({ date: e.target?.value })
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-time-Input">
                                        Time*
                                      </Label>
                                      <input
                                        className="form-control"
                                        type="time"
                                        value={data.time}
                                        id="formrow-time-Input"
                                        onChange={e => {
                                          changeData({ time: e.target?.value })
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  {/* {!showServiceTextField && ( */}
                                  {/* <Col lg={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputDoctor">
                                          Whom to see
                                        </Label>
                                        <select
                                          id="formrow-InputDoctor"
                                          className="form-control"
                                          value={data.doctorId}
                                          onChange={e => {
                                            changeData({
                                              doctorId: e.target?.value,
                                              doctor: doctors?.find(
                                                s => s.id === e.target?.value
                                              ),
                                            })
                                          }}
                                        >
                                          <option>Choose...</option>
                                          {doctors?.map((s, i) => {
                                            return (
                                              <option key={i} value={s?.id}>
                                                {getStaffName(s)}
                                              </option>
                                            )
                                          })}
                                        </select>
                                      </div>
                                    </Col> */}
                                  {/* )} */}
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-time-Input">
                                        Complaint
                                      </Label>
                                      <textarea
                                        className="form-control"
                                        type="time"
                                        value={data.description}
                                        id="formrow-time-Input"
                                        onChange={e => {
                                          changeData({
                                            description: e.target?.value,
                                          })
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </TabPane>
                            <TabPane tabId={4}>
                              <div
                                className="row justify-content-center"
                                style={
                                  submitted
                                    ? { minHeight: "300px" }
                                    : { minHeight: "200px" }
                                }
                              >
                                <Col lg="6">
                                  <div className="text-center">
                                    {submitted && (
                                      <div className="mb-4">
                                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                                      </div>
                                    )}
                                    <div>
                                      <h5>
                                        {submitted
                                          ? "Appointment Booked!"
                                          : "Confirm Detail"}
                                      </h5>
                                      {!submitted ? (
                                        <p className="text-muted">
                                          Book an appointment
                                          {data?.doctor
                                            ? `with ${getStaffName(
                                                data.doctor
                                              )}`
                                            : ""}{" "}
                                          for {data.time} on {data.date}
                                        </p>
                                      ) : (
                                        <p className="text-muted">
                                          Your appointment{" "}
                                          {data?.doctor
                                            ? `with ${getStaffName(
                                                data.doctor
                                              )}`
                                            : ""}{" "}
                                          has been Scheduled for {data.time} on{" "}
                                          {data.date}. Thank you.
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                      </div>
                      {!submitted && (
                        <div
                          className="actions clearfix"
                          style={{
                            display: activeTab === 1 ? "none" : "block",
                          }}
                        >
                          <ul>
                            <li
                              className={
                                activeTab === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                  if (!submitted) {
                                    toggleTab(activeTab - 1)
                                  }
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={`next ${submitted ? "disabled" : ""}`}
                            >
                              <Link
                                to="#"
                                className={`btn btn-primary ${
                                  activeTab === 4 ? "bg-success" : ""
                                }`}
                                onClick={() => {
                                  if (activeTab === 4) {
                                    doSubmit(true)
                                  } else {
                                    if (levelVerified()) {
                                      toggleTab(activeTab + 1)
                                    }
                                  }
                                }}
                              >
                                {activeTab === 4 ? "Submit" : "Next"}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <div
            className="position-fixed top-0 end-0 mt-5 m-1"
            style={{ zIndex: 1100 }}
          >
            {toasts.map(toast => (
              <Toast
                key={toast.id}
                isOpen={true}
                fade={true}
                className={`bg-${toast.type} rounded mb-2`}
              >
                <ToastHeader
                  toggle={() =>
                    setToasts(toasts.filter(t => t.id !== toast.id))
                  }
                >
                  <img src={logoSm} alt="" className="me-2" height="18" />
                  Zoom 36
                  <small className="text-muted ms-1">just now</small>
                </ToastHeader>
                <ToastBody>{toast.content}</ToastBody>
              </Toast>
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Appointments
