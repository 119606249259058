import React, { useEffect, useState } from "react"

const CircularProgress = ({
  size,
  strokeWidth,
  duration,
  colors = { start: "#2f374e", end: "#f44336", bg: "#d5d7db" },
}) => {
  const [progress, setProgress] = useState(100)
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius

  useEffect(() => {
    let startTime = Date.now()

    const interval = setInterval(() => {
      const elapsedTime = (Date.now() - startTime) / 1000
      const percentage = Math.max(0, 100 - (elapsedTime / duration) * 100)
      setProgress(percentage)

      if (elapsedTime >= duration) {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [duration])

  const progressColor = `hsl(${(progress / 100) * 120}, 25%, 25%)`

  return (
    <div style={{ position: "relative", width: size, height: size }}>
      <svg width={size} height={size} style={{ transform: "rotate(-90deg)" }}>
        <circle
          stroke={colors.bg}
          fill="transparent"
          strokeWidth={strokeWidth}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke={progressColor}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (progress / 100) * circumference}
          strokeLinecap="round"
          r={radius}
          cx={size / 2}
          cy={size / 2}
          style={{
            transition: "stroke-dashoffset 1s linear, stroke 1s linear",
          }}
        />
      </svg>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: size / 5,
          color: progressColor,
        }}
      >
        {Math.ceil((progress / 100) * duration)}s
      </div>
    </div>
  )
}

export default CircularProgress
