import axios from "axios"
import { startCase } from "lodash"

export const getStaffName = staff => {
  const test = ["medical director", "doctor", "admin", "dentist"]
  let name = ""
  if (
    test.includes(staff?.role?.slug) ||
    test.includes(staff?.profession?.trim()?.toLowerCase()) ||
    test.includes(staff?.job_title?.trim()?.toLowerCase())
  ) {
    name += "Dr. "
  } else {
    name += "Dt. "
  }
  name += `${staff?.first_name} ${staff?.last_name}`
  return startCase(name)
}

export const getUserName = user => {
  let name = ""
  if (user.title) {
    name += `${user.title} `
  }
  name += `${user?.firstName} ${user?.lastName} ${user.middleName} (${user.rawId})`
  return startCase(name)
}

export const makeRequest = async ({ url, method = "GET", body = null }) => {
  try {
    const response = await axios({
      url: `${process.env[`REACT_APP_SERVER_URL`]}/${url}`,
      method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: body,
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export const locationsArray = ["PHC", "FCT", "LAG"]

export const locationIsGood = str => {
  return locationsArray.includes(str?.toUpperCase())
}
