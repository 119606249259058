import React from "react"
import { BounceLoader } from "react-spinners"

const Loader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <BounceLoader color="#36d7b7" size={60} />
    </div>
  )
}

export default Loader
