import { defaultConfig, post, put, patch, get } from "./api_helper"
import * as url from "./url_helper"

export const sendOtpEmail = data =>
  post(url.VERIFY_USER_EMAIL, data, defaultConfig)

export const sendOtpSms = data => post(url.VERIFY_USER_SMS, data, defaultConfig)

export const verifyOtp = data => patch(url.VERIFY_OTP, data, defaultConfig)

export const testToken = async data => {
  return get(url.TEST_TOKEN, data, {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      authorization: "Bearer " + data.token,
    },
  })
}

export const getRegisteredUsers = query =>
  put(url.GET_USERS_REGISTERED, query, defaultConfig)

export const getUnRegisteredUsers = query =>
  put(url.GET_USERS_UNREGISTERED, query, defaultConfig)

export const createUser = query => post(url.CREATE_USER, query, defaultConfig)

export const fetchAppointments = query =>
  put(url.FETCH_APPOINTMENT, query, defaultConfig)

export const fetchServices = query =>
  put(url.FETCH_SERVICES, query, defaultConfig)

export const fetchDoctors = query =>
  patch(url.FETCH_DOCTORS, query, defaultConfig)

export const bookAppointments = data => {
  return post(url.CREATE_APPOINTMENT, data, {
    ...defaultConfig,
    headers: {
      ...defaultConfig.headers,
      authorization: "Bearer " + data.token,
    },
  })
}
